import React from "react"
import { Box } from "rebass"

const Component = ({ title, desc, src, isFeatured }) => (
  <Box
    width={[1, 1 / 2, 1 / 4]}
    sx={{
      position: "relative",
      display: "inline-flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: 20,
      paddingLeft: "8px",
      paddingRight: "8px",
      boxShadow: isFeatured ? "rgba(0, 0, 0, 0.15) 3px 4px 10px 4px" : "",
      borderRadius: 10,
      cursor: isFeatured ? "pointer" : "inherit",
    }}
    className={isFeatured ? "shadow-hover" : ""}
  >
    {src && (
      <img
        alt={title}
        src={src}
        style={{
          top: 30,
          width: 48,
          height: 48,
          content: " ",
        }}
      />
    )}
    <h4
      style={{
        textAlign: "center",
        margin: "0px 0 10px",
        textDecoration: "none",
        color: "black",
      }}
    >
      {title}
    </h4>
    <p
      style={{
        textAlign: "center",
        fontSize: "0.85em",
        lineHeight: "1.5",
        textDecoration: "none",
        color: "#444",
      }}
    >
      {desc}
    </p>
    {isFeatured && (
      <Box
        sx={{
          backgroundColor: "#0080ff",
          borderRadius: "50%",
          color: "white",
          position: "absolute",
          width: "16px",
          fontWeight: "bold",
          height: "16px",
          margin: "auto",
          paddingLeft: "9px",
          right: "-7px",
          top: "-7px",
        }}
      >
        {" "}
      </Box>
    )}
  </Box>
)

const Feature = ({ href, ...rest }) =>
  href ? (
    <a href={href} style={{ textDecoration: "none" }}>
      <Component {...rest} />
    </a>
  ) : (
    <Component {...rest} />
  )

export default Feature
