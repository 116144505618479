import axios from 'axios';

const getUserCountry = async() => {
    try {
        const response = await axios.get(`https://mopu3pxad3.execute-api.us-east-1.amazonaws.com/prod/user-request`);
        const data = response.data;
        return data.country;
    } catch(e) {
        return "XX";
    }
}

export default getUserCountry;