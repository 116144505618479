import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({
  children,
  showParityOffer,
  hidePreheaderCTA,
  introducingTwoPointZeroCTA
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata.title}
        showParityOffer={showParityOffer}
        hidePreheaderCTA={hidePreheaderCTA}
        introducingTwoPointZeroCTA={introducingTwoPointZeroCTA}
      />
      <main>{children}</main>
    </>
  )
}

export default Layout
