import React from "react"
import { Flex } from "rebass"
import { FooterLink } from "@fragments"
import {
  download,
  memberPortal,
  twitter,
  privacyPolicy,
  changelog,
  pricing,
  eula,
  learn,
} from "@constants/urls.js"

const GuidesFooterMenu = props => (
  <Flex flexDirection="column" textAlign="left" mb={12}>
    <h4 style={{ marginBottom: "0.8rem" }}>DynamoDB Guides</h4>
    <ul style={{ listStyle: "none", marginLeft: 0 }}>
    <FooterLink to={"https://www.dynamodbbook.com/"} text={"DynamoDB Book"} type="external" />
    <FooterLink to={learn.whatIs} text="DynamoDB Intro" type="internal" />
    <FooterLink to={learn.faq} text="DynamoDB FAQ" type="internal" />
    <FooterLink to={learn.tools} text="DynamoDB Tools" type="internal" />
    <FooterLink to={learn.gsi} text="DynamoDB GSI" type="internal" />
    <FooterLink to={learn.ttl} text={"DynamoDB TTL"} type="internal" />
    <FooterLink to={learn.partiql} text="DynamoDB PartiQL" type="internal" />
    <FooterLink to={learn.indexes} text={"DynamoDB Indexes"} type="internal" />
    <FooterLink
      to={learn.triggers}
      text="DynamoDB Triggers"
      type="internal"
    />
    <FooterLink to={learn.vsMongoDB} text={"DynamoDB vs MongoDB"} type="internal" />
    <FooterLink
      to={learn.globalTables}
      text="DynamoDB Global Tables"
      type="internal"
    />
    <FooterLink
      to={learn.bestPractices}
      text={"DynamoDB Best Practices"}
      type="internal"
    />
    </ul>
  </Flex>
)

export default GuidesFooterMenu;