import React from "react"

const container = ({ children, maxWidth }) => (
  <div
    style={{
      margin: `0 auto`,
      maxWidth: maxWidth || 960,
      padding: `0px 1.45rem`,
      paddingTop: 0,
    }}
  >
    {children}
  </div>
)

export default container
