import React, { useState } from "react"
import { Box, Flex } from "rebass"
import Button from "../components/button"

const Plan = ({
  title,
  price,
  downloadUrl,
  pricePerMonth,
  priceTime,
  cta,
  productId,
  coupon,
  licenseDetails,
  isMostPopular,
  planPriceStyles,
  customOnClick,
}) => {
  const teamLicenses = {
    "10": {
      planId: 747458,
      price: "$79",
    },
    "20": {
      planId: 747457,
      price: "$149",
    },
  }
  const [activeProductId, setActiveProductId] = useState(productId)
  const [planPrice, setPlanPrice] = useState(price)

  const updateTeamLicenseDetails = licenseCount => {
    setActiveProductId(teamLicenses[licenseCount]["planId"])
    setPlanPrice(teamLicenses[licenseCount]["price"])
  }

  const TeamLicensesOptions = () => {
    return (
      <li style={{ display: "flex", flexDirection: "row" }}>
        <Box
          style={{
            border: "1px solid #d2d2d2",
            lineHeight: "normal",
            borderRadius: "10px",
          }}
        >
          <span
            style={{
              padding: "0px 12px",
              cursor: "pointer",
              color: planPrice === "$79" ? "black" : "",
              fontWeight: planPrice === "$79" ? "bold" : "400",
              backgroundColor: planPrice === "$79" ? "#f6f6f6" : "",
              borderRadius: "10px 0 0 10px",
              borderRight: "1px solid #d2d2d2",
            }}
            onClick={() => updateTeamLicenseDetails("10")}
          >
            10
          </span>
          <span
            style={{
              padding: "0px 12px",
              cursor: "pointer",
              backgroundColor: planPrice === "$149" ? "#f6f6f6" : "",
              color: planPrice === "$149" ? "black" : "",
              fontWeight: planPrice === "$149" ? "bold" : "400",
              borderRadius: "0 10px 10px 0",
            }}
            onClick={() => updateTeamLicenseDetails("20")}
          >
            20
          </span>
        </Box>
        <strong>&nbsp;Licenses</strong>
      </li>
    )
  }

  return (
    <Box
      width={[1, 0.5, 0.25]}
      sx={{
        position: "relative",
        display: "inline-flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 40,
      }}
      py={10}
      mt={title.indexOf("Team") > -1 ? [0, 42] : 0}
      mx="auto"
    >
      {isMostPopular && (
        <div
          style={{
            top: 0,
            position: "absolute",
            color: "white",
            backgroundColor: "rgb(24, 144, 255)",
            borderRadius: "20px",
            fontWeight: "600",
            fontSize: "0.65em",
            padding: "0 10px",
            zIndex: 999,
          }}
        >
          MOST POPULAR
        </div>
      )}
      <Box
        sx={{
          boxShadow: "rgba(0, 0, 0, 0.15) 3px 4px 10px 4px",
          position: "relative",
          overflow: "hidden",
          minWidth: "75%",
          borderRadius: "5px",
          border: isMostPopular ? "3px solid rgb(24, 144, 255)" : "none",
        }}
        minWidth={["70%", "80%", "90%"]}
        py={10}
        maxWidth="320px"
        className="shadow-hover"
      >
        <h4
          style={{
            textAlign: "center",
            margin: "-30px 0",
            padding: "50px 30px 20px",
            color: "black",
            position: "relative",
            fontSize: "1em",
            fontWeight: "700",
            marginBottom: -10,
          }}
        >
          {title}
        </h4>
        <div
          style={{
            textAlign: "center",
          }}
        >
          <Flex mt={32} justifyContent="center">
            {isMostPopular && (
              <p
                style={{
                  color: "black",
                  position: "relative",
                  fontSize: "1em",
                  fontWeight: 700,
                  fontFamily: "helvetica,sans-serif",
                  textDecoration: "line-through",
                }}
              >
                $249
              </p>
            )}
            <p
              style={{
                color: "black",
                position: "relative",
                fontSize: "3em",
                fontWeight: 700,
                fontFamily: "helvetica,sans-serif",
                ...planPriceStyles,
              }}
            >
              {planPrice}
            </p>
            {pricePerMonth && (
              <span style={{ fontSize: "1.2em", marginTop: "0.6em" }}>/mo</span>
            )}
          </Flex>
          {priceTime && (
            <p
              style={{
                fontSize: "14px",
                marginTop: "-5px",
                // marginBottom: "5px",
                color: "#777",
              }}
            >
              {priceTime}
            </p>
          )}
          <ul
            style={{
              listStyle: "none",
              paddingLeft: "0em",
            }}
            className="checkmark"
          >
            {licenseDetails == "team" ? (
              <TeamLicensesOptions />
            ) : (
              <li>
                <strong>{licenseDetails}</strong>
              </li>
            )}
            <li>Mac, Windows, Linux</li>
            <li>All features</li>
            <li>All future updates</li>
            <li>PartiQL/SQL Support</li>
            <li>Email Support</li>
          </ul>
        </div>
        {customOnClick && (
          <a
            href={customOnClick}
            style={{
              display: "flex",
              margin: "20px auto",
              textAlign: "center",
              justifyContent: "center",
              cursor: "pointer",
              width: "calc(100% - 40px)",
              backgroundColor: "rgb(24, 144, 255)",
              color: "white",
              padding: "10px 20px",
              textDecoration: "none",
              fontWeight: 600,
              borderRadius: "5px",
              boxShadow:
                "rgb(136 144 195 / 20%) 0px 2px 4px 0px, rgb(37 44 97 / 35%) 0px 5px 15px 0px",
            }}
          >
            Contact Us
          </a>
        )}
        {!customOnClick && (
          <Button
            primary
            href={downloadUrl}
            buttonClassName="dynobase-buy-button"
            style={{
              display: "flex",
              margin: "20px auto",
              textAlign: "center",
              justifyContent: "center",
              cursor: "pointer",
              width: "calc(100% - 40px)",
            }}
            onClick={() => {
              window.Paddle.Setup({ vendor: 53624 })

              let paddleObj = {
                product: activeProductId
              }

              if (coupon) {
                paddleObj.coupon = coupon
              }

              window.Paddle.Checkout.open(paddleObj)
            }}
          >
            {cta ? cta : "Buy"}
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default Plan
