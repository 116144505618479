import React from "react"
import { Flex, Text } from "rebass"
import { InternalLink, ExternalLink } from "@fragments"
import {
  download,
  memberPortal,
  twitter,
  privacyPolicy,
  changelog,
  pricing,
  eula,
  jobs
} from "@constants/urls.js"

const FooterInternalLink = ({to, children}) => (
  <InternalLink to={to} style={{
    textDecoration: "none",
    color: "white",
    padding: "2px",
    fontFamily: "helvetica, sans-serif",
    fontSize: "16px",
  }}>
    {children}
  </InternalLink>
)

const FooterExternalLink = ({to, children, ...otherProps}) => (
  <ExternalLink to={to} {...otherProps} style={{
    textDecoration: "none",
    color: "white",
    padding: "2px",
    fontFamily: "helvetica, sans-serif",
    fontSize: "16px",
  }}>
    {children}
  </ExternalLink>
)

const AboutFooterMenu = props => (
  <Flex justifyContent={['center', 'center', 'center', 'center']} flexWrap='wrap'>
      <FooterExternalLink to={download}>Download</FooterExternalLink>
      <Text mx={2}>/</Text>
      <FooterInternalLink to={changelog}>Changelog</FooterInternalLink>
      <Text mx={2}>/</Text>
      <FooterInternalLink to={pricing}>Pricing</FooterInternalLink>
      <Text mx={2}>/</Text>
      <FooterExternalLink to={memberPortal}>Member Portal</FooterExternalLink>
      <Text mx={2}>/</Text>
      <FooterInternalLink to={privacyPolicy}>Privacy</FooterInternalLink>
      <Text mx={2}>/</Text>
      <FooterExternalLink to={eula}>EULA</FooterExternalLink>
      <Text mx={2}>/</Text>
      <FooterExternalLink to={twitter}>Twitter</FooterExternalLink>
  </Flex>
)

export default AboutFooterMenu;
