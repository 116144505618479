import React from "react"
import Footer from "../footer/index"
import ContentCTA from "../ContentCTA"
import Container from "../container"

const FooterWithCta = props => (
  <React.Fragment>
    <Container maxWidth={props.maxWidth}>
      <ContentCTA />
    </Container>
    <Footer />
  </React.Fragment>
)

export default FooterWithCta
