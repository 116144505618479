import React from "react"
import { Box, Flex, Text } from 'rebass'
import AboutMenu from './Menus/About'
import DynamoDBMenu from './Menus/DynamoDB'
import ProductFeatureMenu from './Menus/ProductFeatures'
import FreeToolsMenu from './Menus/FreeTools'
import GuidesMenu from './Menus/Guides';

const Footer = ({customStyle, noMarginTop}) => (
    <Box 
      style={{
        backgroundColor: "#1590FF",
        ...customStyle
      }}
      sx={{
        mt: noMarginTop ? null : [62, 62, 92],
        width: ['100%'],
        color: 'white',
        p: [20]
      }}
    >
    <Flex style={{ textAlign: "center" }} justifyContent={['left', 'left', 'left', 'center']} flexWrap='wrap' mt={22}>
         <ProductFeatureMenu />
         <FreeToolsMenu />
         <DynamoDBMenu />
         <GuidesMenu />  
    </Flex>
        <AboutMenu />
        <Text style={{ textAlign: "center"}} mt={1}>© 2024 Dynobase</Text>
    </Box>
)

export default Footer