import React from "react"
import { Box, Flex, Text, Image } from "rebass"
import { Container } from "@components"
import { InternalLink } from "@fragments"

const PreheaderCTAContent = () => (
  <Box mx="auto">
    <InternalLink to={"/"} noTextDecoration>
      <Flex mx="auto">
        <Image
          src="/images/dynamodb-logo.svg"
          width={"26px"}
          alt="DynamoDB Logo"
        />
        <Text
          py={"12px"}
          textAlign={["center", "center", "center"]}
          color="hsla(0, 0%, 0%, 0.8)"
          marginLeft="8px"
        >
          Learn why <span style={{ fontWeight: "bold" }}>2,000+</span> DynamoDB
          developers use Dynobase every day
        </Text>
        <Text
          py={"12px"}
          pl={2}
          fontWeight="600"
          style={{ textDecoration: "underline" }}
          color="hsla(0, 0%, 0%, 0.8)"
        >
          [click here]
        </Text>
      </Flex>
    </InternalLink>
  </Box>
)

const PreheaderCTA = () => {
  return (
    <Box bg="white" w={"100%"} display={["none", "block", "block"]}>
      <Container>
        <Flex
          p={1}
          flexDirection={["column", "column", "row"]}
          alignItems={["center", "center", "auto"]}
        >
          <PreheaderCTAContent />
        </Flex>
      </Container>
    </Box>
  )
}

export default PreheaderCTA
