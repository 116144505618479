import React from "react"
import { Flex } from "rebass"
import { FooterLink } from "@fragments"
import { learn, feature } from "@constants/urls.js"

const DynamoDBMenu = () => (
  <Flex flexDirection="column" textAlign="left" mr={[64]} mb={12}>
    <h4 style={{ marginBottom: "0.8rem" }}>DynamoDB Info</h4>
    <ul style={{ listStyle: "none", marginLeft: 0 }}>
      <FooterLink
        to={learn.streams}
        text={"DynamoDB Streams"}
        type="internal"
      />
      <FooterLink
        to={learn.vsAurora}
        text={"DynamoDB vs Aurora"}
        type="internal"
      />
      <FooterLink to={learn.keys} text={"DynamoDB Key Types"} type="internal" />
      <FooterLink
        to={learn.dataTypes}
        text={"DynamoDB Data Types"}
        type="internal"
      />
      <FooterLink
        to={learn.runLocally}
        text={"DynamoDB Local Setup"}
        type="internal"
      />
      <FooterLink
        to={learn.transactions}
        text={"DynamoDB Transactions"}
        type="internal"
      />
      <FooterLink
        to={learn.java}
        text={"DynamoDB Java Queries"}
        type="internal"
      />
      <FooterLink
        to={feature.dataModeling}
        text={"DynamoDB Data Modeling"}
        type="internal"
      />
      <FooterLink
        to={learn.awsSam}
        text={"DynamoDB with AWS SAM"}
        type="internal"
      />
      <FooterLink
        to={learn.errors}
        text={"DynamoDB Common Errors"}
        type="internal"
      />
      <FooterLink
        to={learn.capacityModes}
        text={"DynamoDB Capacity Modes"}
        type="internal"
      />
      <FooterLink
        to={learn.queryExamples}
        text={"DynamoDB Query Examples"}
        type="internal"
      />
    </ul>
  </Flex>
)

export default DynamoDBMenu
