const couponCode = {
    "50": "PARITY50",
    "30": "PARITY"
}

const daysLeftMap = {
    "1": "2 Days Left",
    "2": "Last Day"
}

export { couponCode, daysLeftMap}