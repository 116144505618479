import React from "react"
import { Box } from "rebass"
import { GatsbyImage } from "gatsby-plugin-image"

const Section = ({
  allImageSharpNodes,
  title,
  desc,
  imageFirst,
  imageName,
  link,
  linkText,
  background,
}) => {
  const { gatsbyImageData } = allImageSharpNodes.find(
    n => n.fluid.originalName === imageName
  )

  return (
    <div
      style={{
        background: background,
        padding: "1px 40px 40px",
        margin: "-40px",
        marginTop: background ? "40px": "-40px"
      }}
    >
      <Box
        sx={{
          marginTop: [62, 62, 92],
          display: "flex",
          flexDirection: ["column", "column", "row"],
        }}
      >
        <Box
          style={{
            paddingLeft: imageFirst ? 25 : 0,
            paddingRight: !imageFirst ? 25 : 0,
          }}
          width={[1, 1, 1 / 2]}
          sx={{ display: "inline-block" }}
        >
          <h2>{title}</h2>
          <p style={{ marginBottom: 5 }}>{desc}</p>
          {linkText && <a href={link}>{linkText}</a>}
        </Box>
        <Box
          width={[1, 1, 1 / 2]}
          sx={{ display: "inline-block", lineHeight: "0" }}
        >
          <GatsbyImage
            image={gatsbyImageData}
            alt="Section image"
            loading="lazy"
            style={{
              boxShadow: "3px 4px 10px 4px hsla(0, 0%, 0%, 0.15)",
              maxHeight: 275,
              borderRadius: 5,
            }}
          />
        </Box>
      </Box>
    </div>
  )
}

export default Section
