import React from "react"

const ExternalLink = ({ to, children, color, noStyle, ...otherProps }) => {
  return (
    <a
      href={to}
      target="_blank"
      rel="noopener noreferrer"
      style={
        noStyle
          ? { color: "inherit", textDecoration: "none" }
          : { color: color ? color : "null" }
      }
      {...otherProps}
    >
      {children}
    </a>
  )
}

export default ExternalLink
