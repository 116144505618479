import React from "react"
import { Flex } from "rebass"
import { FooterLink } from "@fragments"
import { learn, freeTools, feature } from "@constants/urls.js"

const ToolsFooterMenu = () => (
  <Flex flexDirection="column" textAlign="left" mr={[64]} mb={12}>
    <h4 style={{ marginBottom: "0.8rem" }}>DynamoDB Tools</h4>
    <ul style={{ listStyle: "none", marginLeft: 0 }}>
      <FooterLink
        to={"https://chatwithcloud.ai/"}
        text={"Chat With Cloud"}
        type="internal"
      />
      <FooterLink
        to={learn.cli}
        text={"DynamoDB CLI Queries"}
        type="internal"
      />
      <FooterLink
        to={freeTools.queryBuilder}
        text={"DynamoDB Query Builder"}
        type="internal"
      />
      <FooterLink
        to={learn.boto3}
        text={"DynamoDB Boto3 Queries"}
        type="internal"
      />
      <FooterLink
        to={learn.golang}
        text={"DynamoDB Golang Queries"}
        type="internal"
      />
      <FooterLink
        to={learn.codeExamples}
        text={"DynamoDB Code Examples"}
        type="internal"
      />
      <FooterLink
        to={feature.generateCode}
        text={"DynamoDB Query Codegen"}
        type="internal"
      />
      <FooterLink
        to={learn.nodejs}
        text={"DynamoDB Node.js Queries"}
        type="internal"
      />
      <FooterLink
        to={freeTools.jsonConverter}
        text={"DynamoDB JSON Converter"}
        type="internal"
      />
      <FooterLink
        to={feature.dynamodbLocal}
        text={"DynamoDB Local Admin GUI"}
        type="internal"
      />
      <FooterLink
        to={freeTools.pricingCalculator}
        text={"DynamoDB Pricing Calculator"}
        type="internal"
      />
      <FooterLink
        to={feature.singleTableDesign}
        text={"DynamoDB Single-Table Design"}
        type="internal"
      />
    </ul>
  </Flex>
)

export default ToolsFooterMenu
