import React from "react"
import { Flex } from "rebass"
import { FooterLink } from "@fragments"
import { feature } from "@constants/urls.js"

const ProductFeatureFooterMenu = () => (
  <Flex flexDirection="column" mb={12}>
    <h4 style={{ textAlign: "left", marginBottom: "0.8rem" }}>Product Features</h4>
    {/* <ul style={{ listStyle: "none", marginLeft: 0 }}> */}
    <Flex textAlign="left" mr={[0, 32, 64]}>
      <ul style={{ listStyle: "none", marginLeft: 0, marginRight: "32px" }}>
        <FooterLink
          to={feature.sql}
          text={"Use SQL with DynamoDB"}
          type="internal"
        />
        <FooterLink
          to={feature.importCSV}
          text={"Import CSV To DynamoDB"}
          type="internal"
        />
        <FooterLink
          to={feature.importJSON}
          text={"Import JSON To DynamoDB"}
          type="internal"
        />
        <FooterLink
          to={feature.exportJSON}
          text={"Export DynamoDB To JSON"}
          type="internal"
        />
        <FooterLink
          to={feature.exportToS3}
          text={"Export DynamoDB To S3"}
          type="internal"
        />
        <FooterLink
          to={feature.exportCSV}
          text={"Export DynamoDB To CSV"}
          type="internal"
        />
        <FooterLink
          to={feature.updateItem}
          text={"DynamoDB Update Item"}
          type="internal"
        />
        <FooterLink
          to={feature.truncateTable}
          text={"DynamoDB Delete All Items"}
          type="internal"
        />
        <FooterLink
          to={feature.deleteItem}
          text={"DynamoDB Delete Item"}
          type="internal"
        />
        <FooterLink
          to={feature.createTable}
          text={"DynamoDB Create Table"}
          type="internal"
        />
        <FooterLink
          to={feature.deleteTable}
          text={"DynamoDB Delete Table"}
          type="internal"
        />
        <FooterLink
          to={feature.conditionalUpdate}
          text={"DynamoDB Conditional Update"}
          type="internal"
        />
      </ul>
    </Flex>
  </Flex>
)

export default ProductFeatureFooterMenu
