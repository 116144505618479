import React from 'react';
import { Link } from "gatsby"

const InternalLink = ({to, children, color, noTextDecoration, ...otherProps}) => (
    <Link to={to}
    style={{ 
        color: color ? color : null,
        textDecoration: noTextDecoration ? "none": "underline"
    }}
    {...otherProps}
    >{children}</Link>       
);

export default InternalLink;