import React from "react"
import { Container } from "@components"

const Hero = ({
  title,
  title2,
  includeAuthor,
  includeAuthorCustom,
  customBylineTerm,
  subtitle,
  publishedOn,
  authorImage,
  authorName,
  authorLink,
}) => {
  const finalAuthorLink = authorLink || "https://twitter.com/rafalwilinski";
  const linkRelValue = finalAuthorLink.indexOf("twitter") > -1 ? "nofollow noopener noreferrer": undefined;

  return (
    <React.Fragment>
      <div style={{ backgroundColor: "rgb(24, 144, 255)", width: "100%" }}>
        <Container>
          <div
            style={{
              margin: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            {title2 ? (
              <h1
                style={{
                  color: "white",
                  fontWeight: 600,
                  fontSize: "2.5rem",
                  marginTop: "62px",
                  width: "80%",
                  textAlign: "center",
                  paddingBottom: "6px",
                  lineHeight: "1.5",
                }}
              >
                {title} <br /> {title2}
              </h1>
            ) : (
              <h1
                style={{
                  color: "white",
                  fontWeight: 600,
                  fontSize: "2.5rem",
                  marginTop: "62px",
                  width: "80%",
                  textAlign: "center",
                  paddingBottom: "6px",
                  lineHeight: "1.5",
                }}
              >
                {title}
              </h1>
            )}
            {subtitle && (
              <h2
                style={{
                  color: "white",
                  fontWeight: 400,
                  fontSize: "1em",
                  width: "80%",
                  letterSpacing: 0,
                  marginTop: -20,
                  textAlign: "center",
                  paddingBottom: "6px",
                  lineHeight: "1.45",
                }}
              >
                {subtitle}
              </h2>
            )}
            {includeAuthorCustom && (
              <div style={{ display: "flex", alignContent: "center", justifyContent: "center" }}>
                <a
                  href={finalAuthorLink}
                  rel={linkRelValue}
                >
                  <img
                    src={
                      authorImage ||
                      "https://pbs.twimg.com/profile_images/1390706809548197895/SVCKpLNE_400x400.png"
                    }
                    alt={authorName || "Rafal Wilinski"}
                    style={{
                      width: "32px",
                      height: "32px",
                      borderRadius: "32px",
                      marginRight: "16px",
                    }}
                  />
                </a>
                <div>
                  <p
                    style={{
                      color: "white",
                      fontWeight: 600,
                      fontSize: "0.85rem",
                      textAlign: "left",
                      marginTop: "4px",
                    }}
                  >
                    {customBylineTerm || "Answered"} by{" "}
                    <a
                      href={finalAuthorLink}
                      style={{ color: "inherit" }}
                      target="_blank"
                      rel={linkRelValue}
                    >
                      {authorName || "Rafal Wilinski"}
                    </a>
                  </p>
                </div>
              </div>
            )}
            {includeAuthor && (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <a
                  href={finalAuthorLink}
                  rel={linkRelValue}
                >
                  <img
                    src={
                      authorImage ||
                      "https://pbs.twimg.com/profile_images/1390706809548197895/SVCKpLNE_400x400.png"
                    }
                    alt={authorName || "Rafal Wilinski"}
                    style={{
                      width: "64px",
                      height: "64px",
                      borderRadius: "32px",
                      marginRight: "16px",
                    }}
                  />
                </a>
                <div>
                  <p
                    style={{
                      color: "white",
                      fontWeight: 600,
                      fontSize: "0.85rem",
                      textAlign: "left",
                      marginBottom: -2,
                      marginTop: "8px",
                    }}
                  >
                    Written by{" "}
                    <a
                      href={finalAuthorLink}
                      style={{ color: "inherit" }}
                      target="_blank"
                      rel={linkRelValue}
                    >
                      {authorName || "Rafal Wilinski"}
                    </a>
                  </p>
                  <p
                    style={{
                      color: "#e5e5e5",
                      fontWeight: 400,
                      fontSize: "0.85rem",
                      textAlign: "left",
                      marginBottom: "2rem",
                    }}
                  >
                    Published on {publishedOn}
                  </p>
                </div>
              </div>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Hero
