import React, { useEffect } from 'react';
import { Box, Flex, Text } from 'rebass';
import { Container } from '@components';
import { InternalLink } from '@fragments';
import getUserCountry from '../../utils/getUserCountryCode';
import data from './data';
const countryCodes = Object.keys(data);
import {couponCode, daysLeftMap } from '../OfferCommon/data';

const LimitedTimeSaleBanner = ({countryCode, daysSinceFirstVisit}) => {
    const { discountPercent } = data[countryCode];
    return (
        <Text py={"12px"} textAlign={['center', 'center', 'auto']}>
        🥳 <span style={{fontWeight: 'bold'}}>Price Parity Offer</span>: Use the code <span style={{color: "#e01e5a", fontWeight: 'bold', padding: "5px"}}>{couponCode[discountPercent]}</span> to get {discountPercent}% off all plans
        </Text>
    )
}

const LimitedTimeOffer = () => {
    const [ parityCountry, setParityCountry ] = React.useState("");
    const [ daysSinceFirstVisitVal, setDaysSinceFirstVisitVal ] = React.useState(5);
    const [ showBlackFridayOffer, setShowBlackFridayOffer ] = React.useState(false);

    function handleShowOffer(country) {
        if(countryCodes.includes(country)) {
            setShowBlackFridayOffer(true);
        }
    }

    function findDaysSinceFirstVisit() {
        const visitDateKey = "vd";
        function findDaysBetween(firstVisitDate) {
            const todaysDate = new Date();
            var differenceInMilliseconds = Math.abs(todaysDate - new Date(firstVisitDate));
            var differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
            return differenceInDays;
        }

        const firstVisitDate = localStorage.getItem(visitDateKey);
        if(firstVisitDate) {
            const daysBetween = findDaysBetween(firstVisitDate);
            return daysBetween;
        } else {
            const todaysDate = new Date();
            localStorage.setItem(visitDateKey, todaysDate);
            return 1;
        }
    }

    useEffect(() => {
        async function priceParityCheck() {
            const localStorageKey = "pp";
            const localStorageData = localStorage.getItem(localStorageKey);
            if(localStorageData) {
                setParityCountry(localStorageData);
                const daysSinceFirstVisit = findDaysSinceFirstVisit();
                setDaysSinceFirstVisitVal(daysSinceFirstVisit);
                if(daysSinceFirstVisit <= 2) {
                    handleShowOffer(localStorageData);
                }
            } else {
                const userCountry = await getUserCountry();
                localStorage.setItem(localStorageKey, userCountry);
                setParityCountry(userCountry);
                const daysSinceFirstVisit = findDaysSinceFirstVisit();
                setDaysSinceFirstVisitVal(daysSinceFirstVisit);
                if(daysSinceFirstVisit <= 2) {
                    handleShowOffer(userCountry);
                }
            }
        }
        priceParityCheck();
    }, [])

    return (
        <>
        {
            showBlackFridayOffer &&
            <Box bg='white' w={'100%'} display={["none", "block", "block"]}>
                <Container>
                    <Flex p={1} flexDirection={['column', 'column', 'row']} alignItems={['center', 'center', 'auto']}>
                        <LimitedTimeSaleBanner countryCode={parityCountry} daysSinceFirstVisit={daysSinceFirstVisitVal}/>
                        <InternalLink to={'/buy/'} color='hsla(0, 0%, 0%, 0.8)'>
                            <Text py={"12px"} pl={2} fontWeight="600" style={{textDecoration: "underline"}}>[Click here to get started]</Text>
                        </InternalLink>
                    </Flex>
                </Container>
            </Box>
        }
        </>        
    )
};

export default LimitedTimeOffer;