export default {
  "BR": {
    "flagIcon": "🇧🇷",
    "countryName": "Brazil",
    "discountPercent": "50"
  },
  "IN": {
    "flagIcon": "🇮🇳",
    "countryName": "India",
    "discountPercent": "50"
  },
  "LK": {
    "flagIcon": "🇱🇰",
    "countryName": "Sri Lanka",
    "discountPercent": "50"
  },
  "MX": {
    "flagIcon": "🇲🇽",
    "countryName": "Mexico",
    "discountPercent": "50"
  },
  "TH": {
    "flagIcon": "🇹🇭",
    "countryName": "Thailand",
    "discountPercent": "50"
  },
  "CO": {
    "flagIcon": "🇨🇴",
    "countryName": "Colombia",
    "discountPercent": "50"
  },
  "PE": {
    "flagIcon": "🇵🇪",
    "countryName": "Peru",
    "discountPercent": "50"
  },
  "MY": {
    "flagIcon": "🇲🇾",
    "countryName": "Malaysia",
    "discountPercent": "50"
  },
  "VN": {
    "flagIcon": "🇻🇳",
    "countryName": "Vietnam",
    "discountPercent": "50"
  },
  "PH": {
    "flagIcon": "🇵🇭",
    "countryName": "Philippines",
    "discountPercent": "50"
  },
  "TW": {
    "flagIcon": "🇹🇼",
    "countryName": "Taiwan",
    "discountPercent": "50"
  },
  "AR": {
    "flagIcon": "🇦🇷",
    "countryName": "Argentina",
    "discountPercent": "50"
  },
  "TR": {
    "flagIcon": "🇹🇷",
    "countryName": "Turkey",
    "discountPercent": "50"
  },
  "PK": {
    "flagIcon": "🇵🇰",
    "countryName": "Pakistan",
    "discountPercent": "50"
  },
  "ID": {
    "flagIcon": "🇮🇩",
    "countryName": "Indonesia",
    "discountPercent": "50"
  },
  "NP": {
    "flagIcon": "🇳🇵",
    "countryName": "Nepal",
    "discountPercent": "50"
  },
  "RS": {
    "flagIcon": "🇷🇸",
    "countryName": "Serbia",
    "discountPercent": "50"
  },
  "BG": {
    "flagIcon": "🇧🇬",
    "countryName": "Bulgaria",
    "discountPercent": "50"
  },
  "EG": {
    "flagIcon": "🇪🇬",
    "countryName": "Egypt",
    "discountPercent": "50"
  },
  "CN": {
    "flagIcon": "🇨🇳",
    "countryName": "China",
    "discountPercent": "50"
  },
  "BD": {
    "flagIcon": "🇧🇩",
    "countryName": "Bangladesh",
    "discountPercent": "50"
  },
  "NG": {
    "flagIcon": "🇳🇬",
    "countryName": "Nigeria",
    "discountPercent": "50"
  },
  "UA": {
    "flagIcon": "🇺🇦",
    "countryName": "Ukraine",
    "discountPercent": "30"
  },
  "PL": {
    "flagIcon": "🇵🇱",
    "countryName": "Poland",
    "discountPercent": "30"
  },
  "ES": {
    "flagIcon": "🇪🇸",
    "countryName": "Spain",
    "discountPercent": "30"
  },
  "ZA": {
    "flagIcon": "🇿🇦",
    "countryName": "South Africa",
    "discountPercent": "30"
  },
  "IT": {
    "flagIcon": "🇮🇹",
    "countryName": "Italy",
    "discountPercent": "30"
  },
  "CL": {
    "flagIcon": "🇨🇱",
    "countryName": "Chile",
    "discountPercent": "30"
  },
  "BE": {
    "flagIcon": "🇧🇪",
    "countryName": "Belgium",
    "discountPercent": "30"
  },
  "KR": {
    "flagIcon": "🇰🇷",
    "countryName": "South Korea",
    "discountPercent": "30"
  },
  "PT": {
    "flagIcon": "🇵🇹",
    "countryName": "Portugal",
    "discountPercent": "30"
  },
  "LT": {
    "flagIcon": "🇱🇹",
    "countryName": "Lithuania",
    "discountPercent": "30"
  },
  "CZ": {
    "flagIcon": "🇨🇿",
    "countryName": "Czechia",
    "discountPercent": "30"
  },
  "BY": {
    "flagIcon": "🇧🇾",
    "countryName": "Belarus",
    "discountPercent": "30"
  },
  "RO": {
    "flagIcon": "🇷🇴",
    "countryName": "Romania",
    "discountPercent": "30"
  },
  "HU": {
    "flagIcon": "🇭🇺",
    "countryName": "Hungary",
    "discountPercent": "30"
  },
  "RU": {
    "flagIcon": "🇷🇺",
    "countryName": "Russia",
    "discountPercent": "30"
  },
  "GR": {
    "flagIcon": "🇬🇷",
    "countryName": "Greece",
    "discountPercent": "30"
  },
  "GE": {
    "flagIcon": "🇬🇪",
    "countryName": "Georgia",
    "discountPercent": "30"
  }
}